import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Image from "../components/image"
import { Link } from "gatsby"
import "./blogs.css"
import CustomLink from "../components/elements/custom-link"
import axios from "axios"
import { format } from "date-fns"
const BLOGPAGE = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  // const [starsCount, setStarsCount] = useState(0)

  function ImagesList() {
    // console.log(data)
    // for (let i = 0; i < 5; i++) {
    //   images.push(
    //     <div className="image-blog">
    //       <img
    //         src={"http://localhost:1337" + "/uploads/car_blog_1_6915ec9011.png"}
    //       ></img>
    //       <span className="image-desc">{data.allStrapi}</span>
    //       <span className="image-date">August 16, 2020</span>
    //     </div>
    //   )
    // }

    const images = data.allStrapiBlog.edges.map(item => {
      return (
        <Link to={`/blogs/${item.node.slug}`}>
          <div className="image-blog">
            <Image placeholder="none" media={item.node.image} />
            <span className="image-desc">{item.node.title}</span>
            <span className="image-date">{item.node.date}</span>
          </div>
        </Link>
      )
    })
    return images
  }
  return (
    <>
      <SEO seo={{ title: "Blogs | EV Plugs" }} global={strapiGlobal} />
      <Layout
        pageContext={{
          slug: "404",
        }}
        global={strapiGlobal}
      >
        <div className="container-blog">
          <div className="container">
            <span className="blog-heading">Blogs</span>
            <hr
              className="my-3 md:my-3 hr"
              style={{
                borderTop: "8px solid var(--evp-primary)",
                width: "46px",
              }}
            ></hr>
          </div>
          <div className="image-container container">
            <ListBlog />
            {/* <ImagesList /> */}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BLOGPAGE

// export const query = graphql`
//   query NotFoundQuerY3 {
//     site {
//       siteMetadata {
//         languages {
//           locales
//           defaultLocale
//         }
//       }
//     }
//     strapiGlobal {
//       ...GlobalData
//     }
//   }
// `

// export const query = graphql`
//   query abc {
//     strapiPage(where: { isBlog: true }) {
//       title
//       date
//       image {
//         name
//       }
//     }
//   }
// `
// query DynamicPageQuery($id: String!, $locale: String!) {
//   strapiGlobal(locale: { eq: $locale }) {
//     ...GlobalData
//   }
//   strapiPage(id: { eq: $id }) {
//     slug
//     shortName
//     metadata {
//       metaTitle
//       metaDescription
//       shareImage {
//         localFile {
//           publicURL
//         }
//       }
//     }
//     localizations {
//       id
//       locale
//     }
//     contentSections
//   }

export const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
    allStrapiBlog {
      edges {
        node {
          id
          title

          image {
            url
          }
          slug
        }
      }
    }
  }
`

class ListBlog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const endpoint = "https://cms.evplugs.co.in/blogs"
    const setState = this.setState.bind(this)
    fetch(endpoint, {
      method: "GET",
    })
      .then(resp => resp.json())
      .then(function (response) {
        setState({ data: response })
      })
  }

  render() {
    return (
      <div>
        {this.state.data === null ? (
          <div>
            <div class="sk-chase">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          </div>
        ) : (
          <div className="image-container">
            {this.state.data.map(data => {
              return blogCard(data)
            })}
          </div>
        )}
      </div>
    )
  }
}

function blogCard(data) {
  return (
    <Link to={`/blogs/${data.slug}`}>
      <div className="image-blog">
        <Image className="blog-image" placeholder="none" media={data.image} />
        <span className="image-desc">{data.title}</span>
        <span className="image-date">
          {format(new Date(data.date), "MMMM do, yyyy")}
        </span>
      </div>
    </Link>
  )
}
// class getData extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       loading: true,
//     }
//   }
// }
